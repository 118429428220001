import { FormattedMessage } from 'react-intl';
import type { ComponentPropsWithoutRef } from 'react';
import { ProhibitFilledIcon } from '@pledge-earth/product-language';

import { ErrorBase } from '../Base/ErrorBase';

export function Error403(props: Omit<ComponentPropsWithoutRef<typeof ErrorBase>, 'icon' | 'title' | 'description'>) {
  return (
    <ErrorBase
      image={<ProhibitFilledIcon />}
      title={<FormattedMessage id="auth.403.title" />}
      description={<FormattedMessage id="auth.403" />}
      {...props}
    />
  );
}
